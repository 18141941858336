import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import BenefitCard from "./Components/BenefitCard";
import { ReactComponent as IconeSimple } from "../../assets/svgs/simple.svg";
import { ReactComponent as IconeSimplicity } from "../../assets/svgs/simplicity.svg";
import { ReactComponent as IconeOpportunity } from "../../assets/svgs/opportunity.svg";
import LButton from "../../components/LButtons";
import { FiArrowRight } from "react-icons/fi";
import { useInView } from "react-intersection-observer";
import "./styles.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-scroll";

const Benefits: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const { inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-in-out-sine", once: true });
  }, []);

  useEffect(() => {
    if (inView) {
      AOS.refresh(); 
    }
  }, [inView]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <section id="benefits" className="min-h-screen flex items-center py-16 pt-20 bg-white mb-12">
      <div className="container p-0 mx-auto text-center">
        <div className="overflow-hidden">
          <div 
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <h2 className=" text-2xl sm:text-4xl font-semibold text-gray-800 mb-4">
            BENEFÍCIOS EXCLUSIVOS PARA SUA EMPRESA
            </h2>
          </div>
        </div>

        <div className="overflow-hidden">
          <div 
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >  
            <p className="text-sm sm:text-base sm:font-medium text-gray-600 mb-12">
            Descubra como nossa plataforma pode facilitar o seu processo de licitação.
            </p>
          </div>
        </div>

        {isMobile && (
          <div className="block">
            <Slider {...settings}>
              <div>
                <BenefitCard
                  icon={<IconeSimplicity className="h-16 mb-4" />}
                  title="Simplicidade e"
                  title2="Automação"
                  description="A nossa plataforma cuida de todo o fluxo, desde a inscrição até a análise, permitindo que empresas e órgãos públicos foquem no que realmente importa: fechar bons negócios."
                  data-aos="fade-right"
                />
              </div>
              <div>
                <BenefitCard
                  icon={<IconeOpportunity className="h-16 mb-4" />}
                  title="Encontre Mais"
                  title2="Oportunidades"
                  description="Empresas podem acessar licitações de várias órgãos públicos em um só lugar, facilitando a participação em múltiplas oportunidades de forma rápida e eficiente."
                  data-aos="fade-up"
                />
              </div>
              <div>
                <BenefitCard
                  icon={<IconeSimple className="h-16 mb-4" />}
                  title="Simples e"
                  title2="Intuitivo"
                  description="A interface fácil de usar garante que qualquer empresa ou órgão público possa navegar e participar de licitações sem complicações."
                  data-aos="fade-left"
                />
              </div>
            </Slider>
            <div className="py-6"/>
          </div>
        )}

        {!isMobile && (
          <div className="flex flex-wrap justify-between ">
            <BenefitCard
              icon={<IconeSimplicity className="h-16 mb-4" />}
              title="Simplicidade e"
              title2="Automação"
              description="A nossa plataforma cuida de todo o fluxo, desde a inscrição até a análise, permitindo que empresas e órgãos públicos foquem no que realmente importa: fechar bons negócios."
              data-aos="fade-right"
            />
            <BenefitCard
              icon={<IconeOpportunity className="h-16 mb-4" />}
              title="Encontre Mais"
              title2="Oportunidades"
              description="Empresas podem acessar licitações de várias órgãos públicos em um só lugar, facilitando a participação em múltiplas oportunidades de forma rápida e eficiente."
              data-aos="fade-up"
            />
            <BenefitCard
              icon={<IconeSimple className="h-16 mb-4" />}
              title="Simples e"
              title2="Intuitivo"
              description="A interface fácil de usar garante que qualquer empresa ou órgão público possa navegar e participar de licitações sem complicações."
              data-aos="fade-left"
            />
          </div>
        )}

        <div>
          <Link
            to="pricings"
            smooth={true}
            duration={500}
            className="cursor-pointer no-underline"
          >
            <LButton
              text={
                <>
                  Comece agora <FiArrowRight className="inline ml-2" />
                </>
              }
            />
          </Link>
        </div>
      </div>
    </section>
  );

};

export default Benefits;
