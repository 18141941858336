import React, { useState, useEffect } from "react";
import signingContract from "../../assets/images/signingContract.jpg";
import signingContract2 from "../../assets/images/signingContract.jpg";
import { FiArrowRight } from "react-icons/fi";
import "./styles.css";
import { Link } from "react-scroll";
import LButton from "components/LButtons";

const Home: React.FC = () => {
  const [currentImage, setCurrentImage] = useState(signingContract);
  const [isWhiteText, setIsWhiteText] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1250 || window.innerHeight >= 900 || window.innerWidth >= 1700) {
        setCurrentImage(signingContract2);
        setIsWhiteText(true);
      } else {
        setCurrentImage(signingContract);
        setIsWhiteText(false);
      }

      setIsSmallScreen(window.innerWidth <= 500);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div id="home" className="overflow-hidden w-full h-screen flex items-center justify-center bg-gradient-to-r from-blue-300 via-blue-500 to-transparent" style={{ minHeight: "690px"}}>
      <div
        id="blue-blur-1"
        className="absolute inset-0 z-10 hidden sm:block"
        style={{
          background:
            "linear-gradient(to right, rgba(156,196,255,1.0) 0%, rgba(156,196,255,1.0) 50%, rgba(156,196,255,0.95) 60%, rgba(156,196,255,0.85) 64%, rgba(156,196,255,0.75) 67%, rgba(156,196,255,0.65) 69%, rgba(156,196,255,0.50) 72%, rgba(156,196,255,0.0) 82%)",
          width: "55%",
          minHeight: "690px"
        }}
      />
      <div className="absolute w-screen z-20 text-left px-6 sm:px-12">
        <div className={`w-full font-medium ${isWhiteText ? "text-white" : "text-primary"}`}>
          <h1 className="text-6xl xl:text-6xl 2xl:text-7xl">Licitações</h1>
          <h1 className="text-6xl xl:text-6xl 2xl:text-7xl">Simplificadas</h1>
          <h1 className="text-6xl xl:text-6xl 2xl:text-7xl">com Tecnologia</h1>
          <h1 className="text-6xl xl:text-6xl 2xl:text-7xl">de Ponta</h1>
        </div>
        <p className={`mt-4 flex flex-col text-xl ${isWhiteText ? "text-white" : "text-gray-700"}`}>
          {isSmallScreen ? (
            <>Conectamos órgãos públicos e fornecedores em um ambiente otimizado, onde simplicidade e eficiência se unem.</>
          ) : (
            <>
              <span>Conectamos órgãos públicos e fornecedores em um ambiente</span>
              <span>otimizado, onde simplicidade e eficiência se unem.</span>
            </>
          )}
        </p>
        <div className="divButton mt-6 flex gap-4">
          <Link
            to="pricings"
            smooth={true}
            duration={500}
            className="cursor-pointer no-underline"
          >
            <LButton
              text={
                <>
                Cadastre sua empresa <FiArrowRight className="inline ml-2" />
                </>
              }
              className="w-100"
            />
           
          </Link>
          <button className={`LButton border font-medium px-6 py-2 rounded-md hover:bg-blue-700  ${isWhiteText ? "border-white text-white" : "border-primary text-primary"}`}>
            Explore Oportunidades
          </button>
        </div>
      </div>

      <div>
        <div
          id="blue-blur"
          className="absolute inset-0 z-10 hidden sm:block"
          style={{
            background:
              "linear-gradient(to right, rgba(217,235,255,1) 0%, rgba(156,196,255,1.0) 50%, rgba(156,196,255,0.95) 60%, rgba(156,196,255,0.85) 64%, rgba(156,196,255,0.75) 67%, rgba(156,196,255,0.65) 69%, rgba(156,196,255,0.50) 72%, rgba(156,196,255,0.0) 82%)",
            width: "65%",
            minHeight: "690px"
          }}
        />
        <img
          src={currentImage}
          alt="Background"
          className="absolute top-0 right-0 h-full object-cover z-0 max-w-[80%]"
          style={{ objectPosition: "left bottom", minHeight: "690px" }}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Home;
